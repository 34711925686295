<style scoped>
footer {
  background-color: #111111;
  border-top: 5px solid #fcbc12;
  padding-top: 65px;
  min-height: 35vh;
  background-image: url("@/assets/footer-background.svg");
  background-size: cover;
}

#footer-bottom::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 18px;
  background-image: url("@/assets/footer.svg");
  background-position: bottom;
}
</style>

<template>
  <footer>
    <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      <!-- LOGO DEL SITIO -->
      <div class="flex flex-col">
        <div class="mx-auto transition-all hover:scale-[110%]">
          <a href="/" class="text-xl font-bold text-gray-100 md:text-2xl">
            <img alt="Logotipo Álica Medios" src="@/assets/logo.png" />
          </a>
        </div>
      </div>
      <!-- CONTACTO -->
      <div class="flex flex-col gap-2 text-[#dfe0df] text-lg p-5">
        <div class="mb-6 text-center text-xl">CONTÁCTANOS</div>
        <div class="flex flex-row gap-4">
          <div class="text-[#fcbc12]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-5 h-5"
            >
              <path
                fill-rule="evenodd"
                d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div>Av México 194, Centro, C.P. 63000, Tepic, Nayarit.</div>
        </div>

        <div class="flex flex-row gap-4">
          <div class="text-[#fcbc12]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-5 h-5"
            >
              <path
                d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z"
              />
              <path
                d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z"
              />
            </svg>
          </div>
          <div>contacto@grupoalica.com.mx</div>
        </div>

        <div class="flex flex-row gap-4">
          <div class="text-[#fcbc12]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-5 h-5"
            >
              <path
                fill-rule="evenodd"
                d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div>(+521) 311 133 2300</div>
        </div>
      </div>
      <!-- MAPA DE LA EMPRESA -->
      <div
        class="p-5 flex flex-col place-content-center md:col-span-2 lg:col-span-1 mt-10 lg:mt-0"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14848.314281149753!2d-104.8946751!3d21.5046422!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842736fd1a48ed05%3A0x69d1533b8eda46cf!2sAlica%20Medios!5e0!3m2!1ses-419!2smx!4v1685127720284!5m2!1ses-419!2smx"
          class="w-full h-[300px]"
          style="border: 0"
          allowfullscreen="true"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <!-- LOGOTIPO GRUPO ÁLICA -->
      <div class="md:col-span-2 lg:col-span-3 p-5 my-[100px] flex place-content-center">
        <div class="transition-all hover:scale-125">
          <a href="https://grupoalica.com.mx" target="_blank">
            <img
              alt="Logotipo Grupo Álica"
              src="@/assets/logo-grupo-alica.png"
              width="120"
              height="auto"
            />
          </a>
        </div>
      </div>
    </div>
    <div id="footer-bottom" class="w-full relative"></div>
  </footer>
</template>

<script setup></script>
