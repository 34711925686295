<script setup>
import LogoAlianza from "@/components/LogoAlianza.vue";
import { ref } from "vue";

const alianzas = ref([
  {
    title: "NTV+",
    logo: "logo-ntvplus-resized.png",
    url: "https://www.ntv.com.mx/",
    description: "La página de noticias referente del periodismo digital",
  },
  {
    title: "8NTV",
    logo: "logo-8ntv.png",
    url: "https://www.ntv.com.mx/8ntv/",
    description:
      "El canal de televisión más visto, disponible en señal abierta, cable y plataformas digitales",
  },
  {
    title: "Meridiano Nayarit",
    logo: "logo-meridiano.png",
    url: "https://meridiano.mx/",
    description: "El histórico periódico regional en sus versiones de papel y digital",
  },
]);
</script>

<template>
  <div id="alianzas" class="observable min-h-[80vh] pt-[6vh] md:pt-[7vh] lg:pt-[11vh]">
    <div
      class="min-h-[60vh] container md:px-6 mx-auto flex flex-col pt-[6vh] md:pt-[10vh] mb-5"
    >
      <div
        class="text-[2.5rem] md:text-[3.5rem] lg:text-[5rem] w-full text-center uppercase mt-6 md:mt-0"
      >
        Nuestras alianzas
      </div>
      <!-- <div class="md:h-[6vh]"></div> -->
      <div class="grid sm:grid-cols-1 md:grid-cols-3">
        <LogoAlianza
          v-for="(alianza, key) in alianzas"
          :key="key"
          :alianza="alianza"
          class="p-8 lg:p-[100px]"
        />
      </div>
    </div>
    <div class="h-[10vh]"></div>
  </div>
</template>

<style scoped>
#alianzas {
  background-image: url("@/assets/fonto-estaciones.svg");
  background-attachment: fixed;
}
</style>
