<style scoped>
div#cobertura {
  background-image: url("@/assets/fondo-cobertura.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>

<template>
  <div
    id="cobertura"
    class="observable min-h-[80vh] pb-[10vh] md:pb-[12vh] min-h-[80vh] md:pt-[12vh] lg:pt-[14vh]"
  >
    <div class="h-[18vh] md:h-[5vh]"></div>
    <div class="container mx-auto">
      <div class="flex flex-col lg:flex-row">
        <!-- TeXTO -->
        <div class="flex flex-col md:basis-1/2 place-content-center">
          <div class="text-[#dfe0df]">
            <p class="text-3xl md:text-5xl text-center uppercase px-5 md:p-0">
              TENEMOS LA MEJOR COBERTURA REGIONAL
            </p>
            <p class="text-center text-lg md:text-2xl px-4 md:px-10 my-5 md:my-[50px]">
              Nuestros medios están estratégicamente posicionados en Nayarit, en el sur de
              Sinaloa y en la costa norte de Jalisco.
            </p>
          </div>
        </div>
        <!-- IMAGEN -->
        <div class="basis-1/2 md:px-10">
          <!-- <div class="h-[8vh]"></div> -->
          <div>
            <img src="@/assets/mapa-cobertura.png" alt="Mapa de cobertura estatal" />
          </div>
        </div>
      </div>
    </div>
    <div class="h-[10vh] md:hidden"></div>
  </div>
</template>
